var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"/crear_cuenta"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v("Crear cuenta ")],1),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getCuentas}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"headers":_vm.cuentasHeader,"items":_vm.usuarios,"loading":_vm.loading,"single-select":false,"item-key":"idUsuario","checkbox-color":"secondary","calculate-widths":true,"footer-props":{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [25, 50, -1],
				}},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
				var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.usuarios},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.nombre",fn:function(ref){
				var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"10","color":item.userColor}}),_c('h4',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.nombre)+" ")])],1)]}},{key:"item.disabled",fn:function(ref){
				var item = ref.item;
return [(item.disabled == 1)?_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-account-minus")]):_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-account-plus")])]}},{key:"item.lastLogin",fn:function(ref){
				var item = ref.item;
return [(item.lastLogin)?_c('v-chip',{attrs:{"outlined":""}},[_vm._v(" "+_vm._s(_vm.parseDate(item.lastLogin, false, true, true))+" ")]):_c('span',{staticClass:"text--disabled"},[_vm._v(" Nunca ")])]}},{key:"item.acciones",fn:function(ref){
				var item = ref.item;
return [_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"rounded-pill",attrs:{"outlined":"","small":"","color":"primary","to":("/crear_cuenta?idUsuario=" + (item.idUsuario))}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-pencil")]),_vm._v(" Editar ")],1),_c('v-btn',{staticClass:"rounded-pill",attrs:{"outlined":"","small":"","color":!!Number(item.disabled) ? 'success' : 'error'},on:{"click":function($event){$event.stopPropagation();return _vm.disable(item.idUsuario)}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" "+_vm._s(!!Number(item.disabled) ? 'mdi-account-plus' : 'mdi-account-minus')+" ")]),_vm._v(" "+_vm._s(!!Number(item.disabled) ? "Reactivar" : "Desactivar")+" ")],1),(_vm.$root.acceso('DEV') && _vm.$root.acceso('EDITAR_ESTUDIOS'))?_c('v-btn',{staticClass:"rounded-pill",attrs:{"outlined":"","small":"","color":"secondary"},on:{"click":function($event){$event.stopPropagation();_vm.statsId = item.idUsuario}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v(" mdi-chart-pie ")]),_vm._v(" estadísticas ")],1):_vm._e()],1)]}}])})],1),_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"1000","value":_vm.statsId != null},on:{"input":function (v) { return v === false ? _vm.statsId = null : null; }}},[_c('StatsUsuario',{key:_vm.statsId,attrs:{"idUsuario":_vm.statsId}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }